import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "react-tooltip";
import { useNavigate, Link } from "react-router-dom";
import { Presence } from "phoenix";
import { toast } from "react-hot-toast";
import ReactGA from "react-ga4";
import moment from "moment";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import EditAssignmentModal from "../modals/EditAssignmentModal";
import RoomSecurityModal from "../modals/RoomSecurityModal";
import DeleteAssignmentModal from "../modals/DeleteAssignmentModal";
import ArchiveModal from "../modals/ArchiveModal";
import ShowcaseModal from "../modals/ShowcaseModal";
import { RealtimeModal } from "../modals/RealTimeModal";

import ShareProjectWithOrganizationModal from "../modals/ShareProjectWithOrganizationModal";
import UnshareModal from "../modals/UnshareModal";


import { getPicture } from "../../../utils/get-picture";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
        variant="none"
        className="text-dark"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <i className="bi bi-list" style={{ fontSize: "1.4rem" }}></i>
    </Button>
));

export default function AssignmentCard({
    auth,
    assignment,
    socket,
    refresh,
    refreshSingleRoom,
    showInviteModal,
    showStudentRoomsModal,
    newRoom,
}) {
    const navigate = useNavigate();
    const [channel, setChannel] = useState(null);

    const { getAccessTokenSilently } = useAuth0();

    if (!assignment) return null;

    return (
        <Col sm={12} md={12} lg={12} xxl={12}>
            <Tooltip id="assignmentCard" />
            <Card className="mb-3 mt-3">
                <Row className="p-0 m-0 g-0">
                    <Col className="">
                        <Row className="border-bottom m-0">
                            <Col
                                xs={9}
                                md={8}
                                className="d-flex justify-content-start align-items-center"
                            >
                                <Card.Title className="text-dark mb-0 ms-3 me-3 text-truncate">
                                    {assignment.title}
                                </Card.Title>
                                {newRoom && (
                                    <span className="badge bg-success d-none d-md-block me-2">
                                        Just created!
                                    </span>
                                )}
                                {assignment.related_room.locked && (
                                    <span className="badge bg-secondary d-none d-md-block me-2">
                                        Locked
                                    </span>
                                )}
                                {assignment.shared_to && (
                                    <span
                                        className="badge bg-success d-none d-md-block ms-2"
                                        data-tooltip-id="assignmentCard"
                                        data-tooltip-content={
                                            "Shared with " +
                                            assignment.shared_to.name
                                        }
                                    >
                                        Shared
                                    </span>
                                )}
                            </Col>
                            <Col
                                xs={3}
                                md={4}
                                className="d-flex justify-content-end align-items-center p-1"
                            >
                                <RoomSecurityModal
                                    auth={auth}
                                    refresh={refresh}
                                    refreshSingleRoom={refreshSingleRoom}
                                    object={assignment}
                                    socket={socket}
                                    channel={channel}
                                />
                                <Button
                                    as={Link}
                                    to={"/projects/speech/" + assignment.id}
                                    data-tooltip-id="assignmentCard"
                                    data-tooltip-content="Conversation Insights"
                                    variant="card-dark"
                                >
                                    <i className="bi bi-headset"></i>
                                </Button>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        as={CustomToggle}
                                        variant="white"
                                        className="text-white"
                                        id="dropdown-basic"
                                    ></Dropdown.Toggle>

                                    <Dropdown.Menu>
                                    {auth.profile.has_organization ? (
                                        assignment.shared_to ? (
                                            <UnshareModal
                                                refresh={refresh}
                                                endpoint="assignments/detail"
                                                buttonText="Stop Sharing with Organization"
                                                title={assignment.title}
                                                object={assignment}
                                            />
                                        ) : (
                                            <ShareProjectWithOrganizationModal
                                                refresh={refresh}
                                                endpoint="assignments"
                                                buttonText="Share With Your Organizations"
                                                title={assignment.title}
                                                object={assignment}
                                            />
                                        )
                                    ) : (
                                        <small></small>
                                    )}
                                        <ArchiveModal
                                            refresh={refresh}
                                            endpoint="assignments/detail"
                                            buttonText="Archive"
                                            object={assignment}
                                            title={assignment.title}
                                            id={assignment.id}
                                            socket={socket}
                                        />
                                        <ShowcaseModal
                                            refresh={refresh}
                                            endpoint="assignments/detail"
                                            buttonText="Showcase"
                                            object={assignment}
                                            title={assignment.title}
                                            id={assignment.id}
                                            socket={socket}
                                        />

                                        <EditAssignmentModal
                                            refresh={refresh}
                                            buttonText="Edit"
                                            object={assignment}
                                        />
                                        
                                        <DeleteAssignmentModal
                                            refresh={refresh}
                                            endpoint="assignments"
                                            buttonText="Delete"
                                            title={assignment.title}
                                            id={assignment.id}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row className="m-0 mt-4 mb-4 ms-3">
                            <Col
                                xs={12}
                                sm={4}
                                className="d-flex justify-content-start align-items-center d-none d-sm-block"
                            >
                                <small className="fw-light text-dark mb-0">
                                    {assignment.instructions}
                                </small>
                            </Col>
                            <Col
                                xs={12}
                                sm={8}
                                className="d-flex justify-content-end align-items-center"
                            >
                                <Button
                                    data-tooltip-id="assignmentCard"
                                    data-tooltip-content="Invite users to this space"
                                    onClick={() => showInviteModal(assignment)}
                                    className="me-3"
                                    variant="card-purple-dark"
                                >
                                    <span className="">Share</span>
                                </Button>
                                <Button
                                    onClick={() =>
                                        showStudentRoomsModal(assignment)
                                    }
                                    className="me-2"
                                    variant="success"
                                >
                                    <span className="">
                                        View Student Spaces
                                    </span>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
}
