import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { MathJax, MathJaxContext } from "better-react-mathjax";

import { useAuth } from "../../context/AuthContext";
import { useAuth0 } from "@auth0/auth0-react";
import useSingleRoomAPI from "../../services/useSingleRoomAPI";
import useClassroomSummary from "../../services/useClassroomSummary";

const Section = ({ title, children }) => (
    <div className="mb-4">
        <h6 className="text-uppercase text-muted small fw-bold mb-2">
            {title}
        </h6>
        {children}
    </div>
);

const ExpandableItem = ({ title, description, variant = "milestone" }) => {
    const [expanded, setExpanded] = useState(false);

    const iconClass =
        variant === "milestone"
            ? "bi-check-circle-fill text-success"
            : "bi-dash-circle-fill text-warning";

    return (
        <div className="mb-2">
            <div className="d-flex justify-content-between align-items-start">
                {/* Left: icon + title + optional description */}
                <div className="d-flex align-items-start">
                    <i className={`bi ${iconClass} me-2 mt-1`}></i>
                    <div>
                        <strong>{title}</strong>
                        {expanded && (
                            <div className="text-muted small mt-1">
                                {description}
                            </div>
                        )}
                    </div>
                </div>

                {/* Right: chevron toggle */}
                <div
                    className="ms-3 mt-1 text-muted"
                    style={{ cursor: "pointer" }}
                    onClick={() => setExpanded(!expanded)}
                    title={expanded ? "Hide details" : "Show details"}
                >
                    <i
                        className={`bi ${
                            expanded ? "bi-chevron-up" : "bi-chevron-down"
                        }`}
                    ></i>
                </div>
            </div>
        </div>
    );
};

const GoalEditorModal = ({
    showGoalEditor,
    setShowGoalEditor,
    currentGoal = "",
    project,
    room_id,
    getAccessTokenSilently,
    reload,
}) => {
    const [goalText, setGoalText] = useState(currentGoal);
    const [isPosting, setIsPosting] = useState(false);

    useEffect(() => {
        setGoalText(currentGoal); // Reset when modal is reopened
    }, [currentGoal]);

    const handleSave = () => {
        setIsPosting(true);
        // Handle save
        setShowGoalEditor(false);

        const payload = {
            goals: goalText,
        };

        const endpoint = project
            ? "assignments/detail/" + room_id
            : "classrooms/" + room_id + "/";

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            fetch(process.env.REACT_APP_MM_API_URL + endpoint, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    toast.success(
                        "Changes were saved. Please wait 3-4 minutes for the changes to reflect here."
                    );
                    reload();
                    setShowGoalEditor(false);
                    setIsPosting(false);
                })
                .catch((error) => {
                    toast.error("Something went wrong while saving.");
                    console.error(error);
                    setIsPosting(false);
                });
        });
    };

    return (
        <Modal
            show={showGoalEditor}
            onHide={() => setShowGoalEditor(false)}
            size="lg"
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Learning Goal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column gap-3">
                    <Form.Group controlId="goalTextArea">
                        <Form.Label className="fw-semibold">
                            Grade & Learning Objectives
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            value={goalText}
                            onChange={(e) => setGoalText(e.target.value)}
                            placeholder="The students are 6th graders and should be polite while speaking. They should demonstrate interest in learning languages and attempt to learn something."
                        />
                    </Form.Group>

                    <div className="text-muted small">
                        <i className="bi bi-info-circle me-1"></i>
                        Insights will update automatically within 3–5 minutes to
                        reflect the new goals.
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                        <Button
                            variant="secondary"
                            className="me-2"
                            onClick={() => setShowGoalEditor(false)}
                        >
                            Cancel
                        </Button>
                        <Button variant="success" onClick={handleSave}>
                            Save Changes
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const StudentDetailModal = ({ showModal, setShowModal, selectedStudent }) => {
    const [expandedMessages, setExpandedMessages] = useState(new Set());

    const toggleMessage = (index) => {
        setExpandedMessages((prev) => {
            const newSet = new Set(prev);
            newSet.has(index) ? newSet.delete(index) : newSet.add(index);
            return newSet;
        });
    };

    // Handle math latex rendering
    const mathJaxConfig = {
        loader: { load: ["[tex]/ams"] },
        tex: {
            inlineMath: [["\\(", "\\)"]],
            displayMath: [["\\[", "\\]"]],
        },
    };

    return (
        <MathJaxContext config={mathJaxConfig}>
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="lg"
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedStudent?.name}'s Conversations
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column gap-3">
                        {selectedStudent?.transcripts?.map(
                            (msg, index, arr) => {
                                const isBot = msg.agent;
                                const alignment = isBot ? "start" : "end";
                                const bubbleColor = isBot
                                    ? "bg-light"
                                    : "bg-purple-dark text-white";
                                const time = new Date(
                                    msg.timestamp
                                ).toLocaleString();

                                const prev = arr[index - 1];
                                const newConv =
                                    !prev ||
                                    prev.conversation_id !==
                                        msg.conversation_id;

                                const isExpanded = expandedMessages.has(index);

                                return (
                                    <React.Fragment key={index}>
                                        {newConv && (
                                            <div className="text-center text-muted small my-3 mt-5 pb-3 pt-3 mb-5">
                                                ───── Conversation started on{" "}
                                                {new Date(
                                                    msg.timestamp
                                                ).toLocaleString()}{" "}
                                                ─────
                                            </div>
                                        )}
                                        <div
                                            className={`d-flex justify-content-${alignment}`}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => toggleMessage(index)}
                                        >
                                            {isBot ? (
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <div className="me-2">
                                                        <div
                                                            className="bg-secondary rounded-circle d-flex align-items-center justify-content-center"
                                                            style={{
                                                                width: 36,
                                                                height: 36,
                                                            }}
                                                        >
                                                            <i className="bi bi-robot text-white"></i>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex flex-column align-items-start max-w-75">
                                                        <div className="p-3 rounded-4 bg-light">
                                                            <MathJax
                                                                inline
                                                                dynamic
                                                            >
                                                                {msg.data}
                                                            </MathJax>
                                                        </div>
                                                        {isExpanded && (
                                                            <div className="text-muted small mt-1">
                                                                {time}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className={`d-flex flex-column align-items-${alignment} max-w-75`}
                                                >
                                                    <div
                                                        className={`p-3 rounded-4 ${bubbleColor}`}
                                                    >
                                                        <MathJax inline dynamic>
                                                            {msg.data}
                                                        </MathJax>
                                                    </div>
                                                    <div className="text-muted small mt-1">
                                                        {isExpanded && (
                                                            <small>
                                                                {time}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </React.Fragment>
                                );
                            }
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </MathJaxContext>
    );
};

const SpeechAnalytics = (props) => {
    const { id } = useParams(); // `id`
    const auth = useAuth();

    const { getAccessTokenSilently } = useAuth0();

    const [selectedDate, setSelectedDate] = useState(null);

    const [room, isLoading, isError, reloadRoom] = useSingleRoomAPI(
        id,
        props.project
    );
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showGoalEditor, setShowGoalEditor] = useState(false);

    const [summaryData, summaryLoading, summaryError, refreshSummaries] =
        useClassroomSummary(id, auth.isLoading, props.project);

    if (!auth.profile) {
        return <p>You must be signed in to view this page</p>;
    }

    const speechData = summaryData || {
        totalConvos: 0,
        totalStudents: 0,
        student_list: [],
        class_summary: null,
    };

    const refresh = () => {
        reloadRoom();
        refreshSummaries();
    };

    console.log(props);

    useEffect(() => {
        const interval = setInterval(() => {
            refresh();
        }, 60 * 1000);
        return () => clearInterval(interval);
    }, []);
    const classSummary = speechData.class_summary;

    if (isLoading || summaryLoading) return <p>Loading...</p>;
    if (isError || summaryError) return <p>Error loading data</p>;

    console.log("Summary data", summaryData.student_list.length > 0);

    return (
        <>
            <StudentDetailModal
                setShowModal={setShowModal}
                showModal={showModal}
                selectedStudent={selectedStudent}
            />
            <GoalEditorModal
                setShowGoalEditor={setShowGoalEditor}
                showGoalEditor={showGoalEditor}
                project={props.project}
                room_id={room.hub_id ? room.hub_id : id}
                reload={refresh}
                getAccessTokenSilently={getAccessTokenSilently}
            />
            <Container fluid className="g-0 bg-light">
                <Row className="bg-white p-3 p-lg-4 border-bottom m-0">
                    <Col md={12} lg={7} className="d-flex align-items-center">
                        <h3 className="mb-0">
                            Conversations, Transcripts and Insights for {room.title}
                        </h3>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-lg-end mt-4 mb-2 mt-lg-0 mb-lg-0">
                        <Link
                            to={
                                props.project ? "/student-projects" : "/lessons"
                            }
                        >
                            <i className="bi bi-arrow-left me-2"></i>Back
                        </Link>
                    </Col>
                </Row>
            </Container>
            {summaryData.student_list.length > 0 ? (
                <Container className="mt-4">
                    <Row className="mb-3">
                        <Col>
                            <h5 className="fw-light mb-3">
                                <i className="bi bi-info me-2" />
                                This page is updated automatically aproximatley
                                every 2 minutes (only if there is new student
                                activity).
                            </h5>
                        </Col>
                    </Row>
                    {classSummary ? (
                        <Row className="mb-3">
                            <Col>
                                <Card className="p-2">
                                    <Card.Body>
                                        {/* Title */}
                                        <h5 className="fw-bold mb-3">
                                            <i className="bi bi-bar-chart-line me-2" />
                                            Class Summary
                                        </h5>

                                        {/* Headline */}
                                        <p className="lead text-muted mb-4">
                                            {classSummary.headline}
                                        </p>

                                        {/* Stats section */}
                                        <div className="mb-4">
                                            <div className="d-flex align-items-center mb-2">
                                                <i className="bi bi-star-fill text-success me-2" />
                                                <strong className="me-1">
                                                    Demonstrated Mastery:
                                                </strong>
                                                <span className="text-muted">
                                                    {
                                                        classSummary
                                                            .top_performers
                                                            .length
                                                    }
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center mb-2">
                                                <i className="bi bi-exclamation-circle-fill text-warning me-2" />
                                                <strong className="me-1">
                                                    Need Support:
                                                </strong>
                                                <span className="text-muted">
                                                    {
                                                        classSummary
                                                            .students_needing_help
                                                            .length
                                                    }
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <i className="bi bi-people-fill text-secondary me-2" />
                                                <strong className="me-1">
                                                    Total Students:
                                                </strong>
                                                <span className="text-muted">
                                                    {speechData.totalStudents}
                                                </span>
                                            </div>
                                        </div>

                                        {/* Challenges */}
                                        {classSummary.common_challenges
                                            ?.length > 0 && (
                                            <Section title="Common Challenges">
                                                <ul className="small text-muted mb-3 ps-3">
                                                    {classSummary.common_challenges.map(
                                                        (c, i) => (
                                                            <li key={i}>{c}</li>
                                                        )
                                                    )}
                                                </ul>
                                            </Section>
                                        )}

                                        {/* Engagement */}
                                        {classSummary.engagement_notes && (
                                            <Section title="Engagement Insights">
                                                <p className="text-muted small mb-3">
                                                    {
                                                        classSummary.engagement_notes
                                                    }
                                                </p>
                                            </Section>
                                        )}

                                        {/* Recommendations */}
                                        {classSummary.recommendations?.length >
                                            0 && (
                                            <Section title="Recommendations">
                                                <ul className="small text-muted ps-3 mb-0">
                                                    {classSummary.recommendations.map(
                                                        (r, i) => (
                                                            <li key={i}>{r}</li>
                                                        )
                                                    )}
                                                </ul>
                                            </Section>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <Row className="mb-3">
                            <Col>
                                <Card className="p-2">
                                    <Card.Body>
                                        {/* Title */}
                                        <h5 className="fw-bold mb-3">
                                            <i className="bi bi-bar-chart-line me-2" />
                                            Class Summary
                                        </h5>

                                        {/* Headline */}
                                        <p className="lead text-muted mb-4">
                                            Generating... Please hold on!
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            <Card className="h-100">
                                <Card.Body className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div className="p-2">
                                        <h5 className="fw-bold mb-2 d-flex align-items-center">
                                            <i className="bi bi-bullseye me-2"></i>
                                            Learning Goals for this Activity
                                        </h5>
                                        <p className="mb-0 text-muted">
                                            {room.goals ||
                                                "No goals defined yet."}
                                        </p>
                                    </div>
                                    <div className="p-2">
                                        <Button
                                            variant={
                                                props.project
                                                    ? "success"
                                                    : "secondary-outline"
                                            }
                                            onClick={() => {
                                                // You can hook this up to a modal or inline edit UI
                                                setShowGoalEditor(true);
                                            }}
                                        >
                                            <i className="bi bi-pencil me-1" />
                                            {room.goals
                                                ? "Edit goals"
                                                : "Create goals"}
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <h4 className="mb-3">Student Insights</h4>
                        </Col>
                    </Row>

                    <Row>
                        {speechData.student_list.map((student, index) => (
                            <Col md={6} lg={4} key={index}>
                                <Card className="mb-4 h-100">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-start">
                                            <h5 className="fw-semibold mb-0">
                                                <i className="bi bi-person-circle me-2" />
                                                {student.name}
                                            </h5>
                                            <Button
                                                variant="light"
                                                size="sm"
                                                className=""
                                                onClick={() => {
                                                    setSelectedStudent(student);
                                                    setShowModal(true);
                                                }}
                                            >
                                                View Transcript{" "}
                                                <i className="ms-2 bi bi-chat-left-text" />
                                            </Button>
                                        </div>
                                        <div className="mt-5 mb-5">
                                            <i className="bi bi-chat-left-quote-fill text-muted me-1"></i>
                                            <span className="text-muted">
                                                {student.summary}
                                            </span>
                                        </div>
                                        {student.milestones?.length > 0 && (
                                            <>
                                                {student.milestones.map(
                                                    (m, i) => (
                                                        <ExpandableItem
                                                            key={i}
                                                            title={m.title}
                                                            description={
                                                                m.milestone_text
                                                            }
                                                            variant="milestone"
                                                        />
                                                    )
                                                )}
                                            </>
                                        )}
                                        {student.areas_for_improvement?.length >
                                            0 && (
                                            <>
                                                {student.areas_for_improvement.map(
                                                    (m, i) => (
                                                        <ExpandableItem
                                                            key={i}
                                                            title={m.title}
                                                            description={
                                                                m.description
                                                            }
                                                            variant="improvement"
                                                        />
                                                    )
                                                )}
                                            </>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            ) : (
                <Container className="mt-4">
                    <Row className="mb-3">
                        <Col>
                            <Card>
                                <Card.Body>
                                    <h5 className="fw-light">
                                        <i className="bi bi-info me-2" />
                                        There is no student data yet! This page
                                        updates automatically when there is
                                        stuff to see here. It can take up to 5
                                        minutes after a student is done speaking
                                        for things to appear.
                                    </h5>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default SpeechAnalytics;
