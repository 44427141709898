import React, { useState, useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import "../App.css";

import Profile from "./Profile";
import WhatsNew from "./WhatsNew";
import Home from "./Home";
import Explore from "./Explore";
import Rooms from "./Rooms";
import Admin from "./Admin";
import Assignments from "./Assignments";
import Organization from "./Organization";
import SignupRedirect from "./SignupRedirect";

import NewAssignmentPage from "./assignments/NewAssignmentPage";
import Assessment from "./rooms/Assessment";
import Analytics from "./rooms/Analytics";
import SpeechAnalytics from "./rooms/SpeechAnalytics";

import ListingDetail from "./explore/ListingDetail";
import BundleDetail from "./explore/BundleDetail";
import NewTemplatePage from "./explore/NewTemplate";
import PendingReview from "./explore/PendingReview";

import { DashSidebar } from "../components/Sidebar";
import { GuestSidebar } from "../components/GuestSidebar";
import { Toaster } from "react-hot-toast";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import Container from "react-bootstrap/Container";
import MobileNav from "../components/MobileNav";
import { useNavigate } from "react-router-dom";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import useBannerAPI from "../services/useBannerAPI";

import { Helmet } from "react-helmet";

function Root(props) {
    // From this point, the user should be authed on Auth0, finished onboarding
    const navigate = useNavigate();
    const tawkMessengerRef = useRef();

    const [banners, isBannersLoading, bannersError] = useBannerAPI();

    const [hideSidebar, setHideSidebar] = useState(false);

    const maximizeTawk = () => {
        tawkMessengerRef.current.maximize();
    };
    const { setIdentity } = useTrackingCode();

    useEffect(() => {
        if (props.user && props.profile && props.user.email) {
            // Set HubSpot Tracking Identity if user is logged in
            setIdentity(props.user.email);
        }
    }, [props.user]);

    return (
        <>
            <Helmet>
                <title>MegaMinds</title>
            </Helmet>
            <Toaster
                toastOptions={{
                    duration: 8000,
                }}
            />
            <TawkMessengerReact
                propertyId="62d08e1d7b967b1179999751"
                ref={tawkMessengerRef}
                widgetId="1g7vam9d1"
                customStyle={{ zIndex: "1 !important" }}
            />
            <MobileNav isLoggedIn={props.user} />
            <div className="App d-flex bg-light">
                {!hideSidebar && (
                    <>
                        {props.user ? (
                            <DashSidebar hide={hideSidebar} user={props.user} />
                        ) : (
                            <GuestSidebar hide={hideSidebar} />
                        )}
                    </>
                )}
                <Container as="main" fluid className="g-0 bg-light">
                    {!isBannersLoading &&
                        banners &&
                        banners.map((banner) => (
                            <div className="alert alert-warning mb-0 rounded-0">
                                <p className="mb-0 text-dark">
                                    <i
                                        className={
                                            "bi bi-exclamation-circle-fill me-2"
                                        }
                                    ></i>
                                    {banner.message}
                                </p>
                            </div>
                        ))}
                    <Routes>
                        <Route
                            path="/lessons/analytics/:id"
                            element={<Analytics />}
                        ></Route>
                        <Route
                            path="/lessons/speech/:id"
                            element={<SpeechAnalytics project={false} />}
                        ></Route>
                        <Route
                            path="/projects/speech/:id"
                            element={<SpeechAnalytics project={true} />}
                        ></Route>
                        <Route
                            path="/lessons/assessment/:id"
                            element={<Assessment />}
                        ></Route>
                        <Route
                            path="/collections/:id"
                            element={<BundleDetail />}
                        ></Route>
                        <Route
                            path="/collections/:id/:optionalSlug"
                            element={<BundleDetail />}
                        ></Route>
                        <Route path="/explore">
                            <Route
                                path="review"
                                element={
                                    <PendingReview profile={props.profile} />
                                }
                            ></Route>
                            <Route
                                path=":id"
                                element={<ListingDetail />}
                            ></Route>
                            <Route
                                path=":id/:optionalSlug"
                                element={<ListingDetail />}
                            ></Route>
                            <Route path="" element={<Explore />}></Route>
                        </Route>
                        <Route
                            path="/student-projects"
                            element={<Assignments />}
                        ></Route>
                        <Route
                            path="/new/student-project"
                            element={<NewAssignmentPage />}
                        ></Route>
                        <Route path="/lessons" element={<Rooms />}></Route>
                        <Route
                            path="/whats-new"
                            exact
                            element={<WhatsNew />}
                        ></Route>
                        <Route
                            path="/signup"
                            exact
                            element={
                                <SignupRedirect
                                    hideSidebar={hideSidebar}
                                    setHideSidebar={setHideSidebar}
                                />
                            }
                        ></Route>
                        <Route
                            path="/organization"
                            exact
                            element={<Organization />}
                        ></Route>
                        <Route path="/profile" element={<Profile />}></Route>
                        <Route path="/admin" element={<Admin />}></Route>
                        <Route
                            path="/new-listing"
                            element={<NewTemplatePage />}
                        ></Route>
                        <Route
                            path="/"
                            element={<Home maximizeTawk={maximizeTawk} />}
                        ></Route>
                        <Route path="" exact element={<Home />} />
                        <Route
                            path="*"
                            element={<p>404 - Page not found</p>}
                        ></Route>
                    </Routes>
                </Container>
            </div>
        </>
    );
}

export default Root;
