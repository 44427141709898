// hooks/useClassroomSummary.js or .ts
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useClassroomSummary = (id, isLoading, isProject = false) => {
    const { getAccessTokenSilently } = useAuth0();
    const [summaryData, setSummaryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refresh = () => {
        setRefreshIndex((prev) => prev + 1);
    };

    useEffect(() => {
        if (!isLoading) {
            if (id) {
                (async () => {
                    try {
                        const token = await getAccessTokenSilently({
                            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                            scope: "read:classrooms",
                        });

                        const endpoint = isProject
                            ? "stats/project-summary/" + id
                            : "stats/classroom-summary/" + id;

                        const response = await fetch(
                            process.env.REACT_APP_MM_API_URL + endpoint,
                            {
                                headers: {
                                    Authorization: "Bearer " + token,
                                },
                            }
                        );

                        if (!response.ok) {
                            throw new Error(
                                `Error ${response.status}: ${response.statusText}`
                            );
                        }

                        const data = await response.json();
                        setSummaryData(data);
                        setLoading(false);
                        setError(null);
                    } catch (err) {
                        console.error("Failed to load classroom summary:", err);
                        setError(err);
                        setLoading(false);
                    }
                })();
            } else {
                setSummaryData(null);
                setLoading(false);
            }
        }
    }, [getAccessTokenSilently, id, isLoading, refreshIndex]);

    return [summaryData, loading, error, refresh];
};

export default useClassroomSummary;
